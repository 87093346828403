<template>
	<div class="container mx-auto w-9/12 mt-10">
		<div class="mb-4 h-auto">

			<div class="title">
				<h1>Admin</h1>
			</div>
			
			<transition name="fade" mode="out-in">
				<!-- welcome element -->
				<div 
					v-if="userData && userData.onboardingToShow.admin"
					class="bg-white shadow-lg p-5 w-full rounded flex flex-col md:flex-row items-center"
					>
						<div class="w-full">
							<h2 class="font-extrabold text-gray-800 text-xl">Welcome!</h2>
							<div class="text-gray-700">
								<p class="mt-3">I'm Chris, founder of Referextra.
									<br>
								</p>
								<!-- <p class="mt-3">
									Here's a quick 30-second introduction to Referextra. 
								</p> -->
								<p class="mt-3">I'm available via the <span class="underline font-semibold cursor-pointer" @click="openDriftSupport">chat button </span>for any issues, problems or ideas you have. Let me know your problems with being an affiliate for Hostelworld</p>
								<a href="#" @click.prevent="dismissWelcome" class="text-sm text-gray-500 hover:text-gray-700 animated mt-3 block">Dismiss</a>
							</div>
						</div>
						<!-- <div class="w-full md:w-1/2 flex justify-center items-center p-5">
							<div class="h-64 w-full flex justify-center">
								<iframe
									v-show="view.iframeLoaded"
									@load="iframeLoaded" 
									class="m-auto w-full h-full shadow-2xl rounded"
									src="https://www.youtube.com/embed/0BDCRDgunRo" 
									frameborder="0" 
									allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
									allowfullscreen>
								</iframe>
								<div v-show="!view.iframeLoaded" class="h-full flex flex-col justify-center">
									<Loader />
									<p class="text-xs text-gray-600">
										Loading ideo
									</p>
								</div>
							</div>
						</div> -->
					
				</div>
			</transition>
				

			<transition name="fade" mode="out-in">

				<!-- Loader -->
				<div v-if="!accountsChecked" class="loading w-full text-gray-500 flex mt-10 flex-col items-center" key="loading">
					<Loader />
				</div>

				<!-- Main Content -->
				<div class="w-full" v-else>
					<p>Some overview information...</p>
					<!-- <div class="mb-4 py-5" v-if="accounts.length">
						<h2 class="font-medium">Sites</h2>
						<div class="sites p-4">
							<div class="w-full text-center" v-for="(account, index) in accounts" :key="index">
									<router-link class="block bg-white rounded w-full mb-5 px-3 py-3 shadow-lg hover:shadow-xl animated" :to="'/admin/'+account.id">
										<p class="uppercase text-gray-800 font-bold text-sm">{{account.name}}</p>
										<p v-if="account.locked !== undefined"><span class="text-gray-800 font-bold text-sm">current plan: </span>{{account.locked.plan}}</p>
									</router-link>
							</div>
						</div>
					</div> -->
					<!-- <div class="block bg-white rounded w-full px-3 py-3 text-center">
						<h2 v-if="accounts.length > 0" class="font-medium text-gray-600 text-sm">Add Another Site</h2>
						<h2 v-else class="font-bold text-gray-800 text-lg">Type your site's domain</h2>
						<form 
							id="domainForm"
							@submit="submitDomainForm" 
							class="mt-6 flex flex-col md:flex-row justify-center">
								<input
										id="domain"
										v-model="newDomain"
										type="text"
										name="domain"
										class="w-full md:max-w-xs bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500 mb-3"
										placeholder="awesomesite.com"
								>
								<div class="md:ml-3 text-center md:text-left">
									<button 
										class="shadow bg-purple-500 hover:bg-purple-400 focus:shadow-outline focus:outline-none text-white py-2 px-5 rounded" 
										:class="[{'cursor-not-allowed': !validDomain}, {'opacity-50': !validDomain}]" 
										type="submit" 
										:disabled="!validDomain"
										v-if="!view.domainFormLoading">
										Add
									</button>
									<span v-if="view.domainFormLoading">
										<Loader />
									</span>
								</div>
						</form>
					</div> -->

					<div class="mb-4 py-5" v-if="view.isAdmin">
						<h2 class="font-bold text-xl text-gray-700">Admin area</h2>
						<form @submit.prevent="updateHostels" class="flex">
							<textarea v-model="admin.hostelsToUpdate" name="updateHostels" id="" cols="30" rows="3" class="bg-gray-200 text-xs" placeholder="hostel IDS, comma separated"></textarea>
							<button type="submit" class="button primary">Update Hostels</button>

						</form>

						<hr class="my-4">

						<h3>Inactive hostels</h3>

						<p>
							Send emails
							<label>
								<input type="radio" v-model="sendInactiveEmails" v-bind:value="true">
								Yes
							</label>
							<label>
								<input type="radio" v-model="sendInactiveEmails" v-bind:value="false">
								No
							</label>
						</p>
						<button class="button primary" @click="checkInactiveHostels">Check Inactive Hostels</button>

					</div>
				</div>
			</transition>
		</div>
	</div>
</template>

<script>

// class Account {
// 	constructor(name) {
// 		this.name = name;
// 		this.hwCamref = "";
// 		this.teammates = [];
// 	}
// }

import { db } from '@/main'
import firebase from 'firebase/app'
export default {
	name: 'Admin',
	data: function() {
		return {
			view: {
				domainFormLoading: false,
				iframeLoaded: false,
				isAdmin: false
			},
			sendInactiveEmails: false,
			admin: {
				hostelsToUpdate: ""
			},
			newDomain: "",
			profile: null
	  }
	},
	computed: {
		user () {
			return this.$store.state.user
		},
		userData () {
			return this.$store.state.userData
		},
	  accounts () {
	    return this.$store.state.accounts
		},
	  accountsChecked () {
			return this.$store.state.accountsChecked
		},
		validDomain () {
			// returns true or false based on whether the inputted domain is valid or not
			return !!this.newDomain.match(/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/)
		}
	},
	watch: {
		// accountsChecked: async function(newVal, oldVal) {
		// }
	},
	mounted () {
		// if there's a site in localstorage, set it as the new domain
		// then remove it from localstorage
		let site = localStorage.getItem('site')
		if(site){
			console.log('site:', site);
			this.newDomain = site
			localStorage.removeItem('site')
			// this.submitDomainForm();
		}

		this.checkForAdmin()

	},
	methods: {
		async checkInactiveHostels () {
			console.log("calling API to check inactive hostels")

			let token = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)

			let obj = {
					token: token, 
					sendEmails: this.sendInactiveEmails
			}

			console.log('obj to post:', obj);

			let res = await fetch('/api/inactiveHostels', {
					method: 'post',
					body: JSON.stringify(obj),
					headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
					}
			})

			res = await res.json();

			console.log('res:', res);

			alert("Response:"+ res.message)

		},
		async checkForAdmin () {
			let token = await firebase.auth().currentUser.getIdTokenResult();
			console.log('token:', token);

			if(token.claims.admin){
				this.view.isAdmin = true;
			}
		},
		async updateHostels () {
			console.log("calling API to update all hostels:", this.hostelsToUpdate)

			let token = await firebase.auth().currentUser.getIdToken(/* forceRefresh */ true)

			let obj = {
					token: token, 
					hostelIds: this.admin.hostelsToUpdate
			}
			console.log('obj to post:', obj);

			let res = await fetch('/api/updatehostelbookings', {
					method: 'post',
					body: JSON.stringify(obj),
					headers: {
							'Accept': 'application/json',
							'Content-Type': 'application/json'
					}
			})

			res = await res.json();

			console.log('res:', res);

		},
		async dismissWelcome(){
			// console.log('dismissing welcome message');
			try {
				
				// update user object so they don't see welcome message again
				await db.collection('users').doc(this.user.uid).update({
					"onboardingToShow.admin": false,
					"updatedAt": new Date().getTime()
				})

			} catch (e) {
				console.log(`err updating user doc: `, e)
			}
		},
		// eslint-disable-next-line
		iframeLoaded(event){
			// console.log('iframe loaded:', event);
			this.view.iframeLoaded = true;
		},
		async submitDomainForm (e){
			
			console.log('checking domain input form')
			
			if(e) e.preventDefault();
			
			this.view.domainFormLoading = true

			// account document data
			let newAccount = {
				name: encodeURIComponent(this.newDomain),
				hwCamref: "",
				teammates: [this.user.uid],
				teammateInvites: [],
				owner: this.user.uid
			}

			// we need to create the secure account too
			let newSecureAccount = {
				name: encodeURIComponent(this.newDomain),
				plan: "Starter",
				teammates: [this.user.uid],
				owner: this.user.uid
			}

			let defaultWidget = {
					"createdAt":1569876852894,
					"url":"",
					"tcConfig":{
								"PHCamRef":"secondcam2",
								"PHPubRef":"mypubref",
								"hostelFallbackUrl": null,
								"hostels":[
											{
														"ID":"650",
														"features":"",
														"identifier":"",
														"image":null,
														"name":"Flying Pig Beach Hostel"
											},
											{
														"ID":"93919",
														"features":"",
														"identifier":"",
														"image":null,
														"name":"ClinkNOORD"
											},
								],
								"widgetMode":"accommodation"
					},
					"title":"My first widget",
					"updatedAt":1571569095756
			}

			// Get a new write batch
			var batch = db.batch();

			let accountRef = db.collection("accounts").doc()
			let secureAccountRef = db.collection("accountsSecure").doc(accountRef.id)
			let widgetsRef = accountRef.collection('hwWidgets').doc()

			batch.set(accountRef, newAccount);
			batch.set(secureAccountRef, newSecureAccount )
			batch.set(widgetsRef, defaultWidget)

			// Commit the batch
			try {
				await batch.commit()
	
				// push the user to that site's page
				this.$router.push(`/app/${accountRef.id}`)
			} catch(e) {
				console.log("error batch writing:", e)
			}

		}
	}
}

</script>


<style scoped>
	
</style>